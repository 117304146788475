/* PrintContent.css */
#print-component-c,
#print-component-c * {
    visibility: visible;
    padding: 0;
}
.print-content {
    padding: 20px;
    font-family: Arial, sans-serif; /* Sets a clean font */
  }
  
  h1 {
    font-size: 24px; /* Adjusts heading size */
    margin-bottom: 20px; /* Adds space below the heading */
  }
  
  pre {
    background-color: #f8f8f8; /* Light background for code-like content */
    border: 1px solid #ddd; /* Light border around the content */
    padding: 10px; /* Padding inside the pre element */
    overflow-x: auto; /* Enables horizontal scrolling for long lines */
    white-space: pre-wrap; /* Allows wrapping for long lines */
  }
  