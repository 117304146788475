body {
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: 'Arial', sans-serif;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
}

.login-form {
    background: #e0e0e0;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 8px 8px 16px #bebebe, -8px -8px 16px #ffffff;
    text-align: center;
    width: 100%;
}

.logo {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #555;
}

h2 {
    margin-bottom: 20px;
    color: #333;
}

.input-group {
    margin-bottom: 20px;
    position: relative;
}

.input-group input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 12px;
    background: #F5F6F7;
    box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
    font-size: 16px;
    overflow: hidden;
}

.input-group .password-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.options label {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.options .forgot-password {
    font-size: 14px;
    color: #333;
    text-decoration: none;
}

.sign-in-button {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 12px;
    background:#373435;
    color: white;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 8px 8px 16px #bebebe, -8px -8px 16px #ffffff;
}

.social-login {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

.social-btn {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: #e0e0e0;
    box-shadow: 8px 8px 16px #bebebe, -8px -8px 16px #ffffff;
    font-size: 18px;
    cursor: pointer;
}

.sign-up-text {
    margin-top: 20px;
    font-size: 14px;
}

.sign-up-text a {
    color: #007bff;
    text-decoration: none;
}
