@media print {
    body {
      visibility: hidden;
      counter-reset: page;
    }
  
    #print-component,
    #print-component * {
      visibility: visible;
    }
  
    #print-component1,
    #print-component1 * {
      visibility: visible;
    }
  
    #print-header,
    #print-header * {
      visibility: visible;
      height:50px
    }
  
    #print-measurementSlip,
    #print-measurementSlip * {
      visibility: visible;
    }
  
    #print-thermal,
    #print-thermal * {
      visibility: visible;
    }
  
    #print-header1,
    #print-header1 * {
      visibility: visible;
    }
  
    /* Positioning for print components */
    #print-component {
      position: absolute;
      left: 15px;
      top: 15px;
    }
  
    #print-component1 {
      position: absolute;
      left: 0px;
      top: 20px;
    }
  
    /* Define margins for printed pages */
    @page {
      margin: 20px;
    }
  
    /* Page counter (use in a custom footer if needed) */
    footer::after {
      content: "Page " counter(page);
      position: fixed;
      bottom: 10px;
      right: 10px;
      font-size: 10pt;
    }
  }
  