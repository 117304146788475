.dropdown-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown-wrapper {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2px 12px;
    display: flex;
    flex-direction: column;
    height: 38px;
  }
  
  .dropdown-wrapper:focus-within {
    border-color: gray;
  }
  
  .dropdown-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    font-size: 14px;
    color: gray;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
  
  .dropdown-label-focused {
    top: -10px;
    color: gray;
    font-size: 12px;
  }
  
  .dropdown-select {
    width: 100%;
    padding-left: 3px ;
    padding-top: 8px ;
    padding-bottom: 2px ;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: inherit;
    background: none;
    appearance: none; /* Remove default arrow styles */
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  
  .dropdown-select:focus {
    outline: none;
  }
  
  .dropdown-wrapper::after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: gray;
  }
  