/* signup.module.css */

.loginForm {
	max-width: 'auto';
	padding: 30px 40px 10px 40px;
	border-radius: 5px;
	font-family: "Ubuntu", sans-serif;
}

.formGroup {
	margin-bottom: 10px;
}

.signup {
	max-width: 100%;
	margin: 0 auto;
	padding: 20px;
	border-radius: 7px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	font-family: "Ubuntu", sans-serif;
  }
  
  .signup h2 {
	text-align: center;
	color: black;
	font-size: 35px;
	font-weight: 600;
  }
  
  .group {
	margin-top: 14px;
  }
  
  .label {
	display: block;
	margin-bottom: 2px;
	color: black;
  }
  
  .labelStyles {
	position: absolute;
	top: -12px;
	left: 15px;
	font-size: 12px;
	color: #6e6d6b;
	padding: 2px 5px;
	background-color: white;
  }
  
  .labelFocused {
  
	font-size: 12px;
	color: #03a9f4;
	background-color: white;
  }
  
  .input {
	width: 100%;
	padding: 10px;
	font-size: 14px;
	border: 1px solid #b5b3b3;
	border-radius: 7px;
	background: transparent;
	transition: border-color 0.3s ease;
	color: black;
  }
  
  .input:hover {
	border-color: gray; /* Change the border color on hover */
  }
  
  .input:focus {
	border: 1px solid rgb(6, 179, 247); /* Change the border color on focus */
  }
  
  .select {
	width: 100%;
	padding: 10px;
	font-size: 13px;
	border: 1px solid #b5b3b3;
	border-radius: 7px;
	background: transparent;
	transition: border-color 0.3s ease;
	color: black;
  }
  
  .select:hover {
	border-color: gray; /* Change the border color on hover */
  }
  
  .select:focus {
	border: 1px solid rgb(6, 179, 247); /* Change the border color on focus */
  }
  .button {
	width: 100%;
	background-color: #383535;
	color: white;
	padding: 10px 15px;
	border: none;
	border-radius: 7px;
	cursor: pointer;
	font-size: 16px;
	margin-top: 16px;
  }
  
  .button:hover {
	background-color: #2e302e;
  }
  .btn {
	width: 20%;
	color: black;
	border: 1px solid #b5b3b3;
	margin-left: 5px;
	border-radius: 7px;
  }
  
  .btn:hover {
	background-color: #383535;
	color: white;
  }
  
  .link {
	text-align: center;
  }
  
  .link a {
	color: black;
	text-decoration: none;
	font-weight: bold;
  }
  
  .link a:hover {
	text-decoration: underline;
  }
  
  .tabButton {
	border-bottom: 2px solid transparent;
	color: #000000;
	font-weight: normal;
	padding: 2px;
  }
  
  .active {
	border-color: black;
	font-weight: 500;
  }
  
  .labelActive {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 12px;
	color: #333;
	pointer-events: none;
	transform: translateY(-100%);
	transition: transform 0.3s ease, font-size 0.3s ease;
  }
  
  .input:focus + .labelActive,
  .input:not(:placeholder-shown) + .labelActive {
	transform: translateY(-150%);
	font-size: 10px;
	background-color: transparent;
  }

 .input[type="email"]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Change the color to white */
    background-color: transparent !important; /* Set the background color to white */
    color: #333; /* Text color */
}

.input[type="password"]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Change the color to white */
    background-color: transparent !important; /* Set the background color to white */
    color: #333; /* Text color */
}

.input[type="text"]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Change the color to white */
    background-color: transparent !important; /* Set the background color to white */
    color: #333; /* Text color */
}

.input[type="number"]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Change the color to white */
    background-color: transparent !important; /* Set the background color to white */
    color: #333; /* Text color */
}
