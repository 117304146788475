.outlined-textbox-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.outlined-textbox-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.outlined-textbox-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
  font-size: 11px;
  color: gray;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 100;
}

.outlined-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  padding: 2px 12px;
  height: 38px;
}

.outlined-input-wrapper:focus-within {
  border-color: gray;
}

.outlined-textbox-input {
  width: 100%;
  padding-left: 3px ;
  padding-top: 5px ;
  padding-bottom: 2px ;
  border: none;
  outline: none;
  background-color: transparent;
}

.outlined-textbox-input[readonly] {
  cursor: not-allowed;
  background-color: #f9f9f9;
}

.outlined-textbox-search-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.outlined-textbox-search-btn i {
  color: gray;
}
