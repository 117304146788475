@keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(120%);
    }
  }
  
  .slide-in {
    animation: slideIn 0.5s forwards;
  }
  
  .slide-out {
    animation: slideOut 0.5s forwards;
  }
  